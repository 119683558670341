import styled from 'styled-components';
import { Paragraph, Section } from '../../../components';
import jetsonBackground from '../../../assets/images/jetson_background_desktop.png';
import jetsonBackgroundMobile from '../../../assets/images/jetson_background_mobile.png';
import { SocialBarWrapper } from '../../../components/SocialBar/SocialBar.styled';

export const SectionOneWrapper = styled(Section)`
  background-image: url(${jetsonBackgroundMobile});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 80%;
  margin: 0 -15px 50px;
  padding: 25px 15px 0;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    background-image: url(${jetsonBackground});
    background-position: 300px 60px;
  }

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    background-size: 108%;
  }

  ${Paragraph} {
    margin: 20px 0 30px;

    ${({ theme }) => theme.up(theme.breakpoints.md)} {
      margin: 10px 0 50px;
    }
  }

  ${SocialBarWrapper} {
    margin: 30px 0;
    justify-content: center;
  }
`;

export const TextsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    max-width: 557px;
  }
`;

export const UpperTitle = styled.h3`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 10px;
  position: relative;
  margin-left: 22px;
  display: block;
  margin-bottom: 10px;
  margin-top: 15%;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    letter-spacing: 22px;
    margin-bottom: 30px;
  }

  &::after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    left: -22px;
    top: 0;
    background: ${({ theme }) => theme.palette.orange};
  }
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  width: 0;
  height: 70px;
  left: 176px;
  top: 778px;
`;

export const PerksWrapper = styled.div`
  margin: 0;
`;
