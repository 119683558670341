import styled from 'styled-components';
import { Image, Section } from '../../../components';

export const GalleryWrapper = styled(Section)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 30px 30px;
  grid-template-areas:
    'Photo-1 Photo-2'
    'Photo-3 Photo-5'
    'Photo-4 Photo-5';
  position: relative;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1.5fr 1.5fr;
    grid-template-areas:
      'Photo-1 Photo-1 Photo-2 Photo-3'
      'Photo-4 Photo-5 Photo-5 Photo-3';
  }

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    margin-top: 50px;
  }

  ${Image} {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SectionTitle = styled.h1`
  position: absolute;
  font-size: max(80px, 8vw);
  line-height: max(70px, 7vw);
  color: ${({ theme }) => theme.palette.white};
  opacity: 0.1;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    font-size: 232px;
    line-height: 180px;
    top: 10px;
  }
`;

export const PhotoOverlayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(234, 155, 59, 0.8);
  backdrop-filter: blur(10px);
  position: absolute;
  opacity: 0;
  transition: opacity 0.6s;
  pointer-events: none;
  svg {
    margin-bottom: 20px;
  }
`;

export const PhotoWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    ${PhotoOverlayWrapper} {
      opacity: 1;
    }
  }

  &:nth-of-type(1) {
    grid-area: Photo-1;
  }

  &:nth-of-type(2) {
    grid-area: Photo-2;
  }

  &:nth-of-type(3) {
    grid-area: Photo-3;
  }

  &:nth-of-type(4) {
    grid-area: Photo-4;
  }

  &:nth-of-type(5) {
    grid-area: Photo-5;
  }
`;
