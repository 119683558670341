import styled from 'styled-components';
import { Section } from '../../../components';

export const SectionLatestNewsWrapper = styled(Section)`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-row-gap: 60px;
  grid-column-gap: 30px;
  padding: 90px 0;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1fr 1fr;
  }

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const HomePageNews = styled.div`
  margin: 0;
  padding-top: 100px;
`;
