import styled from 'styled-components';
import { Section } from '../../../components';
import buttonBackground from '../../../assets/images/video_button_background.png';
import jetsonBackground from '../../../assets/images/jetson_background_land_desktop_home.jpg';
// import jetsonBackgroundMobile from '../../../assets/images/jetson_background_mobile.png';

export const SectionStartAndLandWrapper = styled(Section)`
  position: relative;
  margin: 35px 0px;
  padding: 0 20px;
  background: url(${jetsonBackground}) center right no-repeat,
    radial-gradient(
      54.26% 47.2% at 50.12% 50.99%,
      rgba(81, 86, 88, 0.8) 0%,
      rgba(70, 73, 74, 0) 100%
    );
  background-size: contain;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 175px 60px;
    background: url(${jetsonBackground}) right bottom no-repeat,
      radial-gradient(
        54.26% 47.2% at 50.12% 50.99%,
        rgba(81, 86, 88, 0.8) 0%,
        rgba(70, 73, 74, 0) 100%
      );
    background-size: cover;
  }

  h1 {
    max-width: 671px;
  }

  h3 {
    margin: 30px 0 50px;
  }
`;

export const VideoButton = styled.div`
  font-size: 14px;
  line-height: 14px;
  padding: 32px 80px;
  width: calc(100% - 5vw);
  background: url(${buttonBackground});
  letter-spacing: 3px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    right: 15px;
    width: 337px;
    bottom: -50px;
  }
  &:hover {
    filter: grayscale(1) brightness(1.3);
    backdrop-filter: blur(10px);
  }

  svg {
    margin-right: 20px;
  }
`;
