import React from 'react';
import { PulseDot } from '../../../assets/icons';
import { Heading } from '../../../components';
import { useSectionSafetyQuery } from '../../../hooks';
import {
  SectionSafetyWrapper,
  SafetyWrapper,
  FeatureWrapper,
  TextWrapper,
  HeaderWrapper,
} from './SectionSafety.styled';

interface FeatureProps {
  title: string;
  value: string;
}

const Feature = ({ title, value }: FeatureProps) => (
  <FeatureWrapper>
    <PulseDot />
    <TextWrapper>
      <Heading variant="h3">{title}</Heading>
      <Heading variant="h3">{value}</Heading>
    </TextWrapper>
  </FeatureWrapper>
);

const SectionSafety = () => {
  const { title, features } = useSectionSafetyQuery();

  return (
    <SectionSafetyWrapper featuresCount="02">
      <HeaderWrapper>
        <Heading variant="h2">{title}</Heading>
      </HeaderWrapper>
      <SafetyWrapper>
        {features.map((f) => (
          <Feature key={f.title} title={f.title} value={f.value} />
        ))}
      </SafetyWrapper>
    </SectionSafetyWrapper>
  );
};

export default SectionSafety;
