import styled from 'styled-components';
import { Section } from '../../../components';
import jetsonVehicle from '../../../assets/images/jetson_vehicle.png';
import jetsonVehicleDesktop from '../../../assets/images/jetson_vehicle_rotation_desktop.png';

interface WrapperProps {
  featuresCount: string;
}

export const SectionKeyFeaturesWrapper = styled(Section)<WrapperProps>`
  position: relative;
  margin: 35px 0px;
  padding: 0 20px;
  background: url(${jetsonVehicle}) center 70% no-repeat,
    radial-gradient(
      54.26% 53.2% at 60.12% 50.99%,
      rgba(81, 86, 88, 0.8) 0%,
      rgba(70, 73, 74, 0) 100%
    );
  background-size: contain;

  .swiper-slide {
    width: 70%;
  }

  .swiper-scrollbar {
    &::before,
    &::after {
      font-size: 14px;
      letter-spacing: 20%;
    }

    &::before {
      content: '01';
      position: absolute;
      top: -30px;
      left: 0;
    }

    &::after {
      content: '${({ featuresCount }) => featuresCount}';
      position: absolute;
      top: -30px;
      right: 0;
    }
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 25px 60px 200px;
    background: url(${jetsonVehicleDesktop}) right bottom no-repeat,
      radial-gradient(
        54.26% 47.2% at 50.12% 50.99%,
        rgba(81, 86, 88, 0.8) 0%,
        rgba(70, 73, 74, 0) 100%
      );
    background-size: contain;
  }

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    margin: 100px auto 150px;
    padding: 25px 100px;
  }
`;

export const KeyFeaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 1px;
      background: rgba(255, 255, 255, 0.3);
      top: 15px;
      bottom: 15px;
      left: 15px;
    }
  }
`;

export const SectionTitle = styled.h1`
  position: absolute;
  font-size: max(80px, 8vw);
  line-height: max(70px, 7vw);
  max-width: 700px;
  color: ${({ theme }) => theme.palette.white};
  opacity: 0.1;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    font-size: 232px;
    line-height: 180px;
    top: 0;
    transform: translateX(-50%);
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 125px 0 200px;

  svg {
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 0 0 30px;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &:last-of-type {
      padding: 0;
    }

    svg {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }
`;
