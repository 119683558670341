import styled from 'styled-components';
import { Section } from '../../../components';
import jetsonVehicle from '../../../assets/images/jetson_vehicle_front.png';
import jetsonVehicleMobile from '../../../assets/images/jetson_vehicle_front_mobile.png';

interface WrapperProps {
  featuresCount: string;
}

export const SectionTechSpecificationWrapper = styled(Section)<WrapperProps>`
  position: relative;
  background: url(${jetsonVehicleMobile}) no-repeat,
    radial-gradient(
      28.26% 47.2% at 71.12% 51.99%,
      rgba(81, 86, 88, 0.8) 0%,
      rgba(70, 73, 74, 0) 100%
    );
  background-position: center 25%;
  background-size: contain;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    background: url(${jetsonVehicle}) top center no-repeat,
      radial-gradient(
        28.26% 47.2% at 71.12% 51.99%,
        rgba(81, 86, 88, 0.8) 0%,
        rgba(70, 73, 74, 0) 100%
      );
    background-size: auto;
    background-position: center center;
    margin: inherit;
    padding: inherit;
  }

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    padding: 55px 100px 115px;
    margin-bottom: 0;
  }

  .swiper-scrollbar {
    &::before,
    &::after {
      font-size: 14px;
      letter-spacing: 20%;
    }

    &::before {
      content: '01';
      position: absolute;
      top: -30px;
      left: 0;
    }

    &::after {
      content: '${({ featuresCount }) => featuresCount}';
      position: absolute;
      top: -30px;
      right: 0;
    }
  }

  svg {
    margin-bottom: 12px;
  }
`;

export const TechSpecifiactionWrapper = styled.div`
  p {
    font-size: 14px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 60px;

    div:nth-child(even) {
      text-align: right;
    }
  }
`;

export const SectionTitle = styled.h1`
  position: absolute;
  font-size: max(80px, 8vw);
  line-height: max(70px, 7vw);
  color: ${({ theme }) => theme.palette.white};
  opacity: 0.1;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    top: 10%;
  }

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    font-size: 232px;
    line-height: 180px;
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FeaturesMobileCellWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  padding-top: 480px;
  padding-bottom: 50px;

  div {
    text-align: center;
  }
`;
