import React, { useState } from 'react';
import YouTube, { PlayerVars } from 'react-youtube';
import { PlayIcon } from '../../../assets/icons';
import { Heading, Popup } from '../../../components';
import { useSectionStartAndLandQuery } from '../../../hooks';
import { SectionStartAndLandWrapper, VideoButton } from './SectionStartAndLand.styled';

const SectionStartAndLand = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { title, subtitle, videoId } = useSectionStartAndLandQuery();
  const opts = {
    width: '100%',
    height: '606',
    playerVars: {
      autoplay: 1,
      playsinline: 0,
      iv_load_policy: 3,
      loop: 1,
      showInfo: 0,
      modestbranding: 1,
    } as PlayerVars,
  };
  return (
    <SectionStartAndLandWrapper id="section-start-and-land">
      <Heading variant="h1">{title}</Heading>
      <Heading variant="h3">{subtitle}</Heading>
      {/*<VideoButton onClick={() => setIsPopupOpen(true)}>*/}
      {/*  <PlayIcon />*/}
      {/*  WATCH THE VIDEO*/}
      {/*</VideoButton>*/}
      {/*<Popup autoHeight isOpen={isPopupOpen} setIsOpen={setIsPopupOpen}>*/}
      {/*  <YouTube opts={opts} videoId={videoId} />*/}
      {/*</Popup>*/}
    </SectionStartAndLandWrapper>
  );
};

export default SectionStartAndLand;
