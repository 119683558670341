import styled from 'styled-components';
import { Section } from '../../../components';

export const SectionContactWrapper = styled(Section)``;

export const SectionContactFormAndDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  margin-top: 50px;
  grid-row-gap: 30px;
  grid-column-gap: 150px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-top: 140px;
  }
`;

export const SectionContactFormWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-top: 100px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);

    .div1 {
      grid-area: 1 / 1 / 2 / 2;
    }
    .div2 {
      grid-area: 1 / 2 / 2 / 3;
    }
    .div3 {
      grid-area: 1 / 3 / 2 / 3;
    }
    .div4 {
      grid-area: 2 / 0 / 4 / 3;
    }
    .div7 {
      grid-area: 3 / 1 / 5 / 4;
    }
  }
`;

export const SectionContactDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    flex-direction: column;
  }

  div {
    align-self: flex-start;
    :nth-of-type(2) {
      ${({ theme }) => theme.up(theme.breakpoints.md)} {
        margin-top: 30px;
      }
    }
  }

  h3:nth-of-type(1),
  h3:nth-of-type(5) {
    color: ${({ theme }) => theme.palette.orange};
  }

  svg {
    border-radius: 50%;
    ${({ theme }) => theme.up(theme.breakpoints.md)} {
      margin-bottom: 20px;
    }

    &:hover {
      background: ${({ theme }) => theme.palette.orange};
    }
  }
`;
