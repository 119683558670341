import styled from 'styled-components';
import { Section } from '../../../components';

export const SectionOrderYourOwnWrapper = styled(Section)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-row-gap: 25px;
  background: radial-gradient(
    47.02% 46.82% at 54.97% 47.66%,
    rgba(81, 86, 88, 0.6) 0%,
    rgba(70, 73, 74, 0) 100%
  );

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 83px;
  }
  
  h2 {
    text-align: center;
  }
`;

export const ParagraphWrapper = styled.div`
  padding: 30px;
  //border-left: 4px solid ${({ theme }) => theme.palette.darkGrey};
  //margin: 50px 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    width: 50vw;
  }
`;

export const SectionTitle = styled.h1`
  position: relative;
  font-size: max(80px, 8vw);
  line-height: max(70px, 7vw);
  color: ${({ theme }) => theme.palette.white};
  opacity: 0.05;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    font-size: 232px;
    line-height: 180px;
    top: 30%;
    transform: translateX(-50%);
  }
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 0px;
  // display: grid;
  // grid-template-columns: 1fr;
  //
  // ${({ theme }) => theme.up(theme.breakpoints.md)} {
  //   grid-template-columns: 1fr 1.5fr;
  // } ;
`;

export const SlotsWrapper = styled.div`
  margin: 0;
`;

export const HeadingWrapper = styled(Section)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
`;

export const TimelineTitle = styled(Section)`
  margin-top: -120px;
  h2 {
    margin-bottom: 0;
  }
`;

export const ImageWrapper = styled.div`
  margin-left: 100px;
  img {
    max-width: 110%;
  }
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin-left: 250px;
    img {
      max-width: 190%;
    }
  } ;
`;

export const DeliveryWrapper = styled.div``;
