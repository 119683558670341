/* eslint-disable import/extensions */
import React from 'react';
import { useMediaDevice, useSectionWelcomeToTheFutureQuery } from '../../../hooks';
import {
  SectionVideoBackground,
  SectionWelcomeToTheFutureWrapper,
  SectionTitle,
  SectionSubtitle,
  SectionWelcomeDescription,
} from './SectionWelcomeToTheFuture.styled';
import jetsonOneDesktop from '../../../assets/videos/jetson_one_background_desktop.mp4';
import jetsonOneMobile from '../../../assets/videos/jetson_one_background_mobile.mp4';
import { Button, StarSix } from '../../../components';

const SectionWelcomeToTheFuture = () => {
  const { title, subtitle } = useSectionWelcomeToTheFutureQuery();
  const { isMobile, isTablet } = useMediaDevice();
  const scrollToNextSection = () => {
    const element = document.getElementById('section-start-and-land');
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <SectionWelcomeToTheFutureWrapper>
      {/* {!isMobile && !isTablet && <StarSix />} */}
      <SectionWelcomeDescription>
        <SectionTitle>{title}</SectionTitle>
        <SectionSubtitle>{subtitle}</SectionSubtitle>
        {/* <Button arrowDown onClick={scrollToNextSection}> */}
        {/*  LEARN MORE */}
        {/* </Button> */}
      </SectionWelcomeDescription>
      <SectionVideoBackground autoPlay loop muted playsInline>
        <source media="all and (min-width: 721px)" src={jetsonOneDesktop} type="video/mp4" />
        <source media="all and (max-width: 720px)" src={jetsonOneMobile} type="video/mp4" />
      </SectionVideoBackground>
    </SectionWelcomeToTheFutureWrapper>
  );
};

export default SectionWelcomeToTheFuture;
