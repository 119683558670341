import styled from 'styled-components';
import { Section } from '../../../components';

export const SectionAboutHomeWrapper = styled(Section)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-row-gap: 25px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 83px;
  }
`;

export const ParagraphWrapper = styled.div`
  padding: 30px;
  border-left: 4px solid ${({ theme }) => theme.palette.darkGrey};
  margin: 50px 0;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin: 100px 0;
  }
`;

export const SectionTitle = styled.h1`
  position: relative;
  font-size: max(80px, 8vw);
  line-height: max(70px, 7vw);
  color: ${({ theme }) => theme.palette.white};
  opacity: 0.05;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    font-size: 232px;
    line-height: 180px;
    top: 15%;
    transform: translateX(-50%);
  }
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1fr 1.5fr;
  } ;
`;

export const SlotsWrapper = styled.div`
  position: relative;
`;

export const RawWrapper = styled.p`
  margin-top: 30px;
`;

export const TimelineTitle = styled(Section)`
  margin-top: -120px;
`;

export const StarWrapper = styled.div`
  top: 210%;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    top: 210%;
  }
`;
