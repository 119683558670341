import styled from 'styled-components';
import { Section } from '../../../components';
import jetsonVehicle from '../../../assets/images/jetson_safety_background_mobile.png';

interface WrapperProps {
  featuresCount: string;
}

export const SectionUnparalleledSafetyWrapper = styled(Section)<WrapperProps>`
  position: relative;

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    padding: 100px;
    margin-bottom: 0;
  }

  .swiper-slide {
    width: 70%;
  }

  .swiper-scrollbar {
    &::before,
    &::after {
      font-size: 14px;
      letter-spacing: 20%;
    }

    &::before {
      content: '01';
      position: absolute;
      top: -30px;
      left: 0;
    }

    &::after {
      content: '${({ featuresCount }) => featuresCount}';
      position: absolute;
      top: -30px;
      right: 0;
    }
  }

  svg {
    margin-bottom: 12px;
  }
`;

export const UnparalleledSafetyWrapper = styled.div`
  position: relative;
  background: url(${jetsonVehicle}) center 10% no-repeat,
    radial-gradient(
      69.02% 50.82% at 55.97% 59.66%,
      rgba(81, 86, 88, 0.6) 0%,
      rgba(70, 73, 74, 0) 100%
    );
  background-size: contain;
  margin: 0 -15px;
  padding: 0 15px;

  ${({ theme }) => theme.up(theme.breakpoints.sm)} {
    background-position: right center;
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    background: radial-gradient(
      47.02% 46.82% at 59.97% 59.66%,
      rgba(81, 86, 88, 0.6) 0%,
      rgba(70, 73, 74, 0) 100%
    );
    background-size: auto;
  }

  canvas {
    width: 100%;
    height: auto;
    margin-top: -100px;
  }
`;

export const SectionTitle = styled.h1`
  position: absolute;
  font-size: max(80px, 8vw);
  line-height: max(70px, 7vw);
  color: ${({ theme }) => theme.palette.white};
  opacity: 0.1;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    font-size: 232px;
    line-height: 180px;
  }
`;

export const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 375px 0 0;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 0;
  }
`;
