import styled from 'styled-components';
import { Section } from '../../../components';
import newsletterBackground from '../../../assets/images/newsletter_background.jpg';

export const SectionNewsletterWrapper = styled(Section)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: 30px;
  grid-column-gap: 60px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
  }
`;

export const NewsletterDescription = styled.div`
  h1 {
    margin-bottom: 40px;
  }
`;

export const NewsletterCallToActionForm = styled.form`
  padding: 30px;
  background: url(${newsletterBackground}) no-repeat;
  background-size: cover;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: 30px;
  grid-column-gap: 30px;

  ${({ theme }) => theme.up(theme.breakpoints.sm)} {
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin-top: 135px;
    padding: 50px;
  }
`;
