import styled from 'styled-components';
import { Section } from '../../../components';

export const SectionWelcomeToTheFutureWrapper = styled(Section)`
  position: relative;
  text-align: center;
`;

export const SectionTitle = styled.h1`
  font-size: 24px;
  white-space: nowrap;

  ${({ theme }) => theme.up(theme.breakpoints.sm)} {
    text-shadow: 2px 2px 2px rgba(100, 100, 100, 1);
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    font-size: 80px;
  }
`;

export const SectionSubtitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 31px;
  font-family: Rosario, Bebas Neue, georgia, serif, sans-serif;
  max-width: 915px;
  margin-bottom: 30px;

  ${({ theme }) => theme.up(theme.breakpoints.sm)} {
    text-shadow: 2px 2px 2px rgba(100, 100, 100, 1);
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    font-size: 22px;
  }
`;

export const SectionVideoBackground = styled.video`
  width: 100%;
  height: auto;
  margin-top: 23px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin-top: -60px;
  }
`;

export const SectionWelcomeDescription = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  z-index: 1;

  ${({ theme }) => theme.up(theme.breakpoints.sm)} {
    position: absolute;
    max-width: 1360px;
  }

  p + div {
    box-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
  }
`;
