import styled from 'styled-components';
import buttonBackground from '../../../assets/images/video_button_background.png';

export const SectionTwoWrapper = styled.div`
  // display: flex;
  // flex-wrap: wrap;
  // padding: 25px 0 0;
  //
  // h1 {
  //   padding-top: 60px;
  // }
  //
  // ${({ theme }) => theme.up(theme.breakpoints.md)} {
  //   padding: 80px 0 0;
  //
  //   h1 {
  //     padding-top: 0;
  //   }
  // }
  //
  // ${({ theme }) => theme.up(theme.breakpoints.lg)} {
  //   padding: 110px 0 0;
  // }
  //
  // ${({ theme }) => theme.up(theme.breakpoints.md)} {
  //   flex-wrap: nowrap;
  // }
`;

export const VideoSectionWrapper = styled.div`
  width: 100%;
  background-size: cover;
  position: relative;
  height: 40vh;
  margin-left: -15px;
  margin-bottom: 100px;
  padding: 0 0 0 10vw;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    width: 55%;
    height: 640px;
    margin-left: -50px;
    padding: 50px;
  }
`;

export const VideoButton = styled.div`
  font-size: 14px;
  line-height: 14px;
  padding: 32px 80px;
  width: calc(100% - 5vw);
  background: url(${buttonBackground});
  letter-spacing: 3px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: -30px;
  bottom: -40px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    right: 15px;
    width: 337px;
    bottom: -50px;
  }
  &:hover {
    filter: grayscale(1) brightness(1.3);
    backdrop-filter: blur(10px);
  }

  svg {
    margin-right: 20px;
  }
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  text-align: center;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    
  }
`;

export const TextSectionWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  width: 100%;
  //border-left: 1px solid rgba(255, 255, 255, 0.3);

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    width: 50vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 100px;
  }
`;
