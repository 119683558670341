import React from 'react';
import { Heading, Image, RichTextRenderer, StarFour, Timeline } from '../../../components';
import { useMediaDevice, useSectionOrderYourOwnQuery, useSectionBuildsSlots } from '../../../hooks';
import { SectionContact } from '../index';
import {
  SectionOrderYourOwnWrapper,
  ParagraphWrapper,
  DescriptionWrapper,
  SlotsWrapper,
  SectionTitle,
  HeadingWrapper,
  TimelineTitle,
  ImageWrapper,
} from './SectionOrderYourOwn.styled';
import { StarsWrapper } from '../../../components/Animated/Stars/StarFour/StarFour';

const SectionOrderYourOwn = () => {
  const {
    title,
    description,
    image: {
      file: { fileName, url },
    },
  } = useSectionOrderYourOwnQuery();

  const { build2022, build2023 } = useSectionBuildsSlots();

  const { isMobile, isTablet } = useMediaDevice();

  return (
    <SectionOrderYourOwnWrapper>
      <Heading variant="h2">{title}</Heading>
      <DescriptionWrapper>
        <ParagraphWrapper>
          <RichTextRenderer>{description}</RichTextRenderer>
        </ParagraphWrapper>
        {/*<ImageWrapper>*/}
        {/*  <Image alt={fileName} src={url} />*/}
        {/*</ImageWrapper>*/}
      </DescriptionWrapper>

      <HeadingWrapper>
        {/*<SectionTitle>BUILD SLOTS</SectionTitle>*/}
        <Heading variant="h2">Delivery 2022</Heading>
        <Heading variant="h4">The entire 2022 production is sold out</Heading>
      </HeadingWrapper>
      <SlotsWrapper>
        {/*<StarsWrapper>{!isMobile && !isTablet && <StarFour />}</StarsWrapper>*/}
        <Timeline items={build2022} />
      </SlotsWrapper>

      <HeadingWrapper>
        <TimelineTitle>
          <Heading variant="h2">Delivery 2023</Heading>
          <Heading variant="h4">We are taking orders for 2023 delivery</Heading>
        </TimelineTitle>
      </HeadingWrapper>
      <SlotsWrapper>
        <Timeline items={build2023} />
      </SlotsWrapper>

      {/*<SectionContact />*/}
    </SectionOrderYourOwnWrapper>
  );
};

export default SectionOrderYourOwn;
