import styled from 'styled-components';
import { Section } from '../../../components';

export const SectionSinglePostWrapper = styled(Section)``;

export const SectionSinglePostHeader = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-content: center;

  h2 {
    font-size: 46px;
  }
`;

export const SectionSinglePostMeta = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  text-align: center;
  color: ${({ theme }) => theme.palette.grey};
`;

export const SectionSinglePostMainImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  margin: 30px 0;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin: 30px 0 100px;
  }
`;
