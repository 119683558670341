/* eslint-disable import/extensions */
import styled from 'styled-components';
import { Section } from '../../../components';
import jetsonVehicle from '../../../assets/images/jetson_safety_background.png';

interface WrapperProps {
  featuresCount: string;
}

export const SectionSafetyWrapper = styled(Section)<WrapperProps>`
  position: relative;
  background: url(${jetsonVehicle}) no-repeat,
    radial-gradient(
      28.26% 47.2% at 71.12% 51.99%,
      rgba(81, 86, 88, 0.8) 0%,
      rgba(70, 73, 74, 0) 100%
    );
  background-position: center 25%;
  background-size: contain;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    background: url(${jetsonVehicle}) no-repeat;
    background-size: 70% auto;
    background-position: center center;
    margin: inherit;
    padding: inherit;

    &:after {
      content: '';
      left: -50%;
      right: -50%;
      top: -50%;
      bottom: -50%;
      height: 200%;
      width: 200%;
      position: absolute;
      z-index: -1;
      background: radial-gradient(
        41.26% 28.2% at 50.12% 51.99%,
        rgba(81, 86, 88, 0.8) 0%,
        rgba(70, 73, 74, 0) 100%
      );
    }
  }

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    padding: 100px;
    margin-bottom: 0;
  }

  .swiper-scrollbar {
    &::before,
    &::after {
      font-size: 14px;
      letter-spacing: 20%;
    }

    &::before {
      content: '01';
      position: absolute;
      top: -30px;
      left: 0;
    }

    &::after {
      content: '${({ featuresCount }) => featuresCount}';
      position: absolute;
      top: -30px;
      right: 0;
    }
  }
`;

export const SafetyWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-row-gap: 20px;
  padding-top: 70vw;
  margin: 100px 0;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 60px;
    padding-top: 0;
    margin: 100px 0 0;
  }
`;

export const SectionTitle = styled.h1`
  color: ${({ theme }) => theme.palette.white};
  text-align: center;
`;

export const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    div {
      text-align: left;
      margin-left: 30px;
    }

    :nth-child(even) {
      flex-direction: row-reverse;
      text-align: right;

      div {
        text-align: right;
        margin-right: 30px;
      }
    }
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 369px;
  margin-left: 30px;
`;

export const FeaturesMobileCellWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  padding-top: 280px;
  padding-bottom: 50px;

  div {
    text-align: left;

    ${TextWrapper} {
      margin-left: 30px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  text-align: center;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    //margin-left: 50%;
    //max-width: 300px;
    text-align: center;
    //position: relative;
    //transform: translateX(-75%);

    //&:after {
    //  content: 'ONE';
    //  position: absolute;
    //  right: -130px;
    //  font-size: 105px;
    //  top: 53%;
    //  transform: translateY(-50%);
    //}
  }
`;
