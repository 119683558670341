import styled from 'styled-components';
import React, { memo } from 'react';

export const StarsWrapper = styled.div`
  position: absolute;
  width: 979px;
  height: 1126px;
  bottom: -240%;
  right: 20%;
  pointer-events: none;

  svg .svg-elem-1 {
    stroke-dashoffset: 4682.71337890625px;
    stroke-dasharray: 4682.71337890625px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  }

  svg.active .svg-elem-1 {
    stroke-dashoffset: 9365.4267578125px;
  }

  svg .svg-elem-2 {
    stroke-dashoffset: 4687.7412109375px;
    stroke-dasharray: 4687.7412109375px;
    -webkit-transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
    transition: stroke-dashoffset 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s;
  }

  svg.active .svg-elem-2 {
    stroke-dashoffset: 9375.482421875px;
  }
`;

const StarSeven = () => {
  return (
    <StarsWrapper>
      <svg
        fill="none"
        height="1252"
        viewBox="0 0 1124 1252"
        width="1124"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M601.269 1208.31L559.715 1000.16H102.625L601.269 750.367L933.698 1L871.367 750.367L1224.57 1000.16H767.483L601.269 1208.31Z"
          stroke="#EA9B3B"
        />
        <path
          d="M499.153 1249.06L499.363 1250.12L500.034 1249.28L666.099 1041.31H1122.95H1124.52L1123.24 1040.4L770.264 790.774L832.571 41.6918L831.615 41.4476L499.259 790.65L0.776058 1040.36L1 1041.31H457.68L499.153 1249.06Z"
          opacity="0.21"
          stroke="white"
        />
        /{'>'}
      </svg>
    </StarsWrapper>
  );
};

export default memo(StarSeven);
