import React, { useMemo } from 'react';
import { Heading, SingleArticleTile } from '../../../components';
import { useSectionArticlesQuery } from '../../../hooks';
import {
  SectionSimilarNewsWrapper,
  SectionSimilarNewsContentWrapper,
} from './SectionSimilarNews.styled';

interface SectionSimilarNewsProps {
  currentArticleSlug: string;
}

const SectionSimilarNews = ({ currentArticleSlug }: SectionSimilarNewsProps) => {
  const { nodes: articles } = useSectionArticlesQuery();
  const filteredArticles = useMemo(
    () => articles.filter((article) => article.slug !== currentArticleSlug).slice(0, 3),
    [articles, currentArticleSlug],
  );

  return (
    <SectionSimilarNewsWrapper>
      <Heading variant="h1">SIMILAR NEWS</Heading>
      <SectionSimilarNewsContentWrapper>
        {filteredArticles?.map((article) => (
          <SingleArticleTile key={article.slug} {...article} />
        ))}
      </SectionSimilarNewsContentWrapper>
    </SectionSimilarNewsWrapper>
  );
};

export default SectionSimilarNews;
