import React from 'react';
import { Heading } from '../../../components';
import { useSectionTechSpecQuery } from '../../../hooks';
import {
  SectionTechSpecWrapper,
  SectionTechSpecTable,
  SectionTechSpecRow,
  BackgroundButton,
} from './SectionTechSpec.styled';

const SectionTechSpec = () => {
  const { entryTitle, techSpecPerks } = useSectionTechSpecQuery();
  return (
    <SectionTechSpecWrapper>
      <Heading variant="h2">{entryTitle}</Heading>
      <SectionTechSpecTable>
        {techSpecPerks.map(({ title, value }) => (
          <SectionTechSpecRow key={title}>
            <span>{title}</span>
            <span>{value}</span>
          </SectionTechSpecRow>
        ))}
      </SectionTechSpecTable>
      {/*{!isMobile && !isTablet && <StarFive />}*/}
      {/*<BackgroundButton to="/order">order your own</BackgroundButton>*/}
    </SectionTechSpecWrapper>
  );
};

export default SectionTechSpec;
