import styled from 'styled-components';
import { Section } from '../../../components';

export const SectionSimilarNewsWrapper = styled(Section)`
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 0;
  }
  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    padding: 0 100px 0;
  }
`;

export const SectionSimilarNewsContentWrapper = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-row-gap: 60px;
  grid-column-gap: 30px;
  margin-top: 50px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1fr 1fr;
    margin-top: 70px;
  }

  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
