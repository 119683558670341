import React, { useState } from 'react';
import YouTube, { PlayerVars } from 'react-youtube';
import { PlayIcon } from '../../../assets/icons';
import { Heading, Popup, RichTextRenderer } from '../../../components';
import { useSectionTwoQuery } from '../../../hooks';
import {
  SectionTwoWrapper,
  VideoButton,
  VideoSectionWrapper,
  TextSectionWrapper,
  HeadingWrapper,
} from './SectionTwo.styled';

const SectionTwo = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { title, description, videoId, buttonText, image } = useSectionTwoQuery();
  const videoSectionBackground = image.file.url;

  const opts = {
    width: '100%',
    height: '606',
    playerVars: {
      autoplay: 1,
      playsinline: 0,
      iv_load_policy: 3,
      loop: 1,
      showInfo: 0,
      modestbranding: 1,
    } as PlayerVars,
  };

  return (
    <SectionTwoWrapper>
      {/*<VideoSectionWrapper style={{ backgroundImage: `url(${videoSectionBackground}` }}>*/}
      {/*  <HeadingWrapper>*/}
      {/*    <Heading variant="h1">{title}</Heading>*/}
      {/*  </HeadingWrapper>*/}

      {/*  /!*<VideoButton onClick={() => setIsPopupOpen(true)}>*!/*/}
      {/*  /!*  <PlayIcon />*!/*/}
      {/*  /!*  {buttonText}*!/*/}
      {/*  /!*</VideoButton>*!/*/}
      {/*  /!*<Popup autoHeight isOpen={isPopupOpen} setIsOpen={setIsPopupOpen}>*!/*/}
      {/*  /!*  <YouTube opts={opts} videoId={videoId} />*!/*/}
      {/*  /!*</Popup>*!/*/}
      {/*</VideoSectionWrapper>*/}
      <HeadingWrapper>
        <Heading variant="h2">{title}</Heading>
      </HeadingWrapper>
      <TextSectionWrapper>
        <RichTextRenderer>{description}</RichTextRenderer>
      </TextSectionWrapper>
    </SectionTwoWrapper>
  );
};

export default SectionTwo;
