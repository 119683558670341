import styled from 'styled-components';
import { Section } from '../../../components';

export const SectionMainArticleWrapper = styled(Section)`
  ${({ theme }) => theme.up(theme.breakpoints.lg)} {
    padding: 50px 100px 0;
  }
`;

export const MainArticleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  margin-top: 50px;
  grid-row-gap: 30px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1.25fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
  }
`;

export const MainArticlePhoto = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MainArticleContent = styled.div`
  h6 {
    color: ${({ theme }) => theme.palette.grey};
    margin: 10px 0;
  }
`;

export const RichTextRendererWrapper = styled.div`
  max-width: 557px;
  height: 413px;
  overflow: hidden;

  > div {
    height: 413px;
    overflow: hidden;
  }
`;
