import styled from 'styled-components';
import { Section } from '../../../components';

export const SectionEveryoneIsAPilotWrapper = styled(Section)`
  position: relative;
  text-align: center;
`;

export const SectionTitle = styled.h1`
  font-size: 40px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
  white-space: nowrap;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    font-size: 126px;
    width: 960px;
  }
`;

export const SectionSubtitle = styled.p`
  position: absolute;
  top: 80px;
  font-size: 18px;
  font-weight: 600;
  line-height: 33px;
  left: 50%;
  transform: translateX(-50%);
  text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
  width: 90%;
  text-transform: uppercase;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    top: 250px;
    width: 954px;
    font-size: 28px;
  }
`;

export const SectionVideoBackground = styled.video`
  width: 100%;
  height: auto;
  margin-top: 23px;
  cursor: pointer;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin-top: 70px;
  }
`;
