import React from 'react';
import readingTime from 'reading-time';
import { Heading, RichTextRenderer } from '../../../components';
import { formatDate } from '../../../helpers';
import {
  SectionSinglePostHeader,
  SectionSinglePostWrapper,
  SectionSinglePostMeta,
  SectionSinglePostMainImage,
} from './SectionSinglePost.styled';

interface SectionSinglePostProps {
  title: string;
  mainImage: {
    file: {
      fileName: string;
      url: string;
    };
  };
  content: {
    raw: string;
  };
  createdAt: string;
}

const SectionSinglePost = ({ title, content, mainImage, createdAt }: SectionSinglePostProps) => {
  const { text: timeToRead } = readingTime(content.raw);
  return (
    <SectionSinglePostWrapper>
      <SectionSinglePostHeader>
        <Heading variant="h2">{title}</Heading>
        <SectionSinglePostMeta>
          {formatDate(createdAt)} {timeToRead}
        </SectionSinglePostMeta>
      </SectionSinglePostHeader>
      <SectionSinglePostMainImage src={mainImage.file.url} />
      <RichTextRenderer>{content}</RichTextRenderer>
    </SectionSinglePostWrapper>
  );
};

export default SectionSinglePost;
