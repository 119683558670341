import styled from 'styled-components';
import { Section } from '../../../components';
import { RichTextRenderedWrapper } from '../../../components/RichTextRenderer/RichTextRendered.styled';

export const SectionSixWrapper = styled(Section)`
  background: radial-gradient(
    31.07% 31.58% at 53.03% 64.78%,
    rgba(81, 86, 88, 0.6) 0%,
    rgba(70, 73, 74, 0) 100%
  );
`;

export const ColumnsSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  gap: 0px 30px;
  margin-top: 20px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin-top: 100px;
  }
  ${RichTextRenderedWrapper} {
    margin-bottom: 20px;
  }
`;

export const QuoteSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  align-items: center;
  margin: 30px 0 0;
  position: relative;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    margin: 100px 0 90px;
  }
`;

export const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  max-width: 582px;
`;

export const QuoteText = styled.blockquote`
  font-family: Noto Serif, Rosario, Bebas Neue, georgia, serif, sans-serif;
  font-size: 18px;
  line-height: 32px;
  font-style: italic;
  padding-left: 20px;
  border-left: 4px solid ${({ theme }) => theme.palette.orange};
  margin-bottom: 30px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    font-size: 28px;
    line-height: 50px;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  margin: 50px -30px 0 0;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin: 0;
  }

  svg {
    position: absolute;
    top: -50px;
    left: -5px;
    height: 156px;
    width: 124px;

    ${({ theme }) => theme.up(theme.breakpoints.md)} {
      left: -75px;
    }
  }
`;

export const VideoPlayer = styled.video`
  width: 100%;
  height: auto;
`;
