import styled from 'styled-components';
import { Section } from '../../../components';

export const SectionFoundersWrapper = styled(Section)`
  margin-bottom: 0;
`;

export const FoundersWrapper = styled.div`
  display: flex;
  margin: 50px 0;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin: 100px 0 0;
  }
`;
