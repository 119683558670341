import styled from 'styled-components';
import { Link } from 'gatsby';
import { Section } from '../../../components';
import buttonBackground from '../../../assets/images/big_button_background.png';

export const SectionTechSpecWrapper = styled(Section)`
  justify-content: center;
  position: relative;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }
`;

export const SectionTechSpecTable = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.grey};
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    width: 50vw;
  }
`;

export const SectionTechSpecRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey};

  &:last-of-type {
    border-bottom: none;
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    padding: 10px 20px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  span {
    font-size: 14px;
    line-height: 16px;

    &:last-of-type {
      font-size: 22px;
      line-height: 26px;
    }

    ${({ theme }) => theme.up(theme.breakpoints.md)} {
      font-size: 18px;
      line-height: 21px;

      &:last-of-type {
        font-size: 22px;
        line-height: 33px;
      }
    }
  }
`;

export const BackgroundButton = styled(Link)`
  font-size: 48px;
  line-height: 52px;
  padding: 28px 30px 20px;
  width: 100%;
  background: url(${buttonBackground});
  letter-spacing: 3px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 50px;
  color: white;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    margin-top: 150px;
    font-size: 96px;
    line-height: 115px;
    padding: 45px 80px 32px;
  }

  &:hover {
    filter: grayscale(1) brightness(1.3);
    backdrop-filter: blur(10px);
  }

  &:active,
  &:visited {
    color: white;
  }
  svg {
    margin-right: 20px;
  }
`;
