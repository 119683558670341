import styled from 'styled-components';

export const SectionRevolutionWrapper = styled.div`
  background: radial-gradient(
    33.26% 40.2% at 50.12% 38.99%,
    rgba(81, 86, 88, 0.8) 0%,
    rgba(70, 73, 74, 0) 100%
  );

  h1 {
    width: 100%;
    text-align: center;

    ${({ theme }) => theme.up(theme.breakpoints.md)} {
      padding-top: 100px;
    }
  }
`;

export const RevolutionTilesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto auto 1fr;
  text-align: center;
  margin-top: 100px;

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    height: 1200px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    text-align: left;
  }
`;

interface RevolutionTileWrapperProps {
  withBackground?: boolean;
}

export const RevolutionTileWrapper = styled.div<RevolutionTileWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background: ${({ withBackground }) => (withBackground ? '#d1d1d4' : 'transparent')};
  padding: ${({ withBackground }) => (withBackground ? '0' : '10%')};

  img {
    align-self: flex-start;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: left;
    pointer-events: none;

    ${({ theme }) => theme.up(theme.breakpoints.lg)} {
      object-fit: contain;
      transform: scale(1.555);
      object-position: initial;
    }
  }

  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    font-size: 58px;
  }
`;
